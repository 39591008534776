<template>
  <div class="px-16">
    <div class="flex row">
      <div class="w-1/3" v-for="event in events" :key="event.id">
        <div class="max-w-xs my-2 overflow-hidden rounded shadow-lg">
          <div class="px-6 py-4">
            <div class="mb-2 text-xl font-bold text-center border-b-4">{{ event.title }}</div>
            <p class="text-base text-center text-grey-darker">
              <span class="text-gray-700">Start date: </span><br/>
              {{ getEventStartDate(event) }}
            </p>
          </div>
          <div class="px-6 py-4 border-t border-b">
            {{ getEventDescription(event) }}
          </div>
          <div class="px-6 py-4 text-center">
            <button type="button" class="w-10/12 px-6 py-2 text-white bg-blue-400 rounded"
                    @click="openEvent(event.id)">
              Click to view
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'HomeView',
  data: () => {
    return {
      events: []
    }
  },
  created () {
    axios.get('event')
        .then((resp) => {
          this.events = resp.data.data
        })
  },
  methods: {
    openEvent: function (id) {
      this.$router.push({name: 'event.info', params: {id: id}})
    },
    getEventStartDate (event) {
      return event ? moment(event.startDateString).format(event.dateFormat.js) : null
    },
    getEventDescription (event) {
      return event ? event.description?.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br />') : null
    }
  }
}
</script>

<style scoped>

</style>